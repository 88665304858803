export const emptyPlayer: Player = {
    ad: false,
    baseStats: {
        be: null,
        st: null,
        ge: null,
        wg: null,
        rw: null,
        skill: null
    },
    hv: null,
    id: 0,
    kosten: 0,
    name: "",
    position: "",
    ssp: 0,
    stats: {
        be: null,
        st: null,
        ge: null,
        wg: null,
        rw: null,
        skill: null
    },
    teamId: 0,
    vhs: false,
    wert: 0
};

export const emptyTeamOverview: TeamOverview = {
    art: "",
    cheerleader: 0,
    fans: 0,
    id: 0,
    kasse: 0,
    name: "",
    niederlagen: 0,
    punkte: 0,
    rerolls: {
        anzahl: 0,
        kosten: 0
    },
    sani: 0,
    siege: 0,
    sonderregeln: "",
    spiele: 0,
    spieler: [],
    touchdowns: 0,
    trainer: "",
    vsa: 0,
    trainerass: 0,
    unentschieden: 0,
    value: {
        all: 0,
        actual: 0
    },
    spieltag: 0
};

export const emptyTeam: Team = {id: 0, name: ''};

export const emptyWetter: Wetter = {id: 0, name: '', description: ''};

export const emptyTeamData: TeamData = {id: 0, name: '', punkte: 0, nuffle: '', gesellen: 0, anreize: '', handkasse: 0, teamkasse: 0, einnahmen: 0, fans: 0, teamwert: 0, bewertung: 0, rerollCost: 0, kostSpieligeFehler:0};

export const emptySpielBerichtDataSave: SpielBerichtDataSave = {
    spieltag: 0,
    aussenseiter: emptyTeam,
    gast: emptyTeamData,
    heim: emptyTeamData,
    kickoff: emptyTeam,
    wetter: emptyWetter,
    events: [],
    playerSspList: [],
    hired: [],
    hiredStaff: [],
    firedPlayer: []
};

export const emptySpielBerichtData: SpielBerichtData = {
    aussenseiter: "",
    gast: emptyTeamData,
    heim: emptyTeamData,
    kickoff: "",
    wetter: "",
    events: [],
    playerSspList: [],
    hired: []
};

export const emptyEnddat: Enddates = {
    id: 0,
    tag: 0,
    bet: null,
    editTeam: null
}