import React from "react";

export default function TeamData(props: TeamDataProps) {
    return (<table className="col-sm-12 table table-bordered table-sm">
        <tbody>
        <tr>
            <td>Wiederholungswürfe:</td>
            <td className="textCenter">{props.data.rerolls.anzahl}</td>
            <td className="textCenter">X</td>
            <td className="textRight">{props.data.rerolls.kosten.toLocaleString()}</td>
            <td className="textRight">{(props.data.rerolls.anzahl * props.data.rerolls.kosten).toLocaleString()}</td>
        </tr>
        <tr>
            <td>Trainerassistenten:</td>
            <td className="textCenter">{props.data.trainerass}</td>
            <td className="textCenter">X</td>
            <td className="textRight">10.000</td>
            <td className="textRight">{(props.data.trainerass * 10000).toLocaleString()}</td>
        </tr>
        <tr>
            <td>Cheerleader:</td>
            <td className="textCenter">{props.data.cheerleader}</td>
            <td className="textCenter">X</td>
            <td className="textRight">10.000</td>
            <td className="textRight">{(props.data.cheerleader * 10000).toLocaleString()}</td>
        </tr>
        <tr>
            <td>Sanitäter:</td>
            <td className="textCenter">{props.data.sani}</td>
            <td className="textCenter">X</td>
            <td className="textRight">50.000</td>
            <td className="textRight">{(props.data.sani * 50000).toLocaleString()}</td>
        </tr>
        <tr>
            <td>Teamwert:</td>
            <td colSpan={4} className="textRight">{props.data.value.all.toLocaleString()}</td>
        </tr>
        <tr>
            <td>Momentaner Teamwert:</td>
            <td colSpan={4} className="textRight">{props.data.value.actual.toLocaleString()}</td>
        </tr>
        </tbody>
    </table>);
}