import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import {Button} from "react-bootstrap";
import Form from "react-bootstrap/Form";

export default function NewPlayerRow(props: NewPlayerRowProps) {
    return (
        <tr>
            <td>{props.pos}</td>
            <td>
                <Form.Control type="text" defaultValue={props.player.name} onChange={event => props.player.name = event.target.value} />
            </td>
            <td>{props.player.position}</td>
            <td>{props.player.stats.be}</td>
            <td>{props.player.stats.st}</td>
            <td>{props.player.stats.ge}</td>
            <td>{props.player.stats.wg}</td>
            <td>{props.player.stats.rw}</td>
            <td>{props.player.stats.skill}</td>
            <td className="textRight">{props.player.kosten.toLocaleString()}</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td className="textRight">{props.player.kosten.toLocaleString()}</td>
            <td colSpan={4}>
                <Button variant="primary" size="sm" onClick={(e) => {
                    props.deleteRow(props.player);
                    e.preventDefault();
                }}>
                    <FontAwesomeIcon icon={faTrash}/>
                </Button>
            </td>
        </tr>
    );
}