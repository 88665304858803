import React from "react";
import Modal from 'react-bootstrap/Modal';
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import Team from "./Team";

export default function NewMemberModal(props: MemberModalProps) {

    return (
        <Modal show={props.show}
               onHide={props.handleClose}
               backdrop="static"
               size="xl"
               keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>Neue Spieler Anheuern</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Table striped hover={true}>
                    <thead>
                    <tr>
                        <th>Typ</th>
                        <th>be</th>
                        <th>st</th>
                        <th>ge</th>
                        <th>wg</th>
                        <th>rw</th>
                        <th>skill</th>
                        <th>Kosten</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        props.member.map(player =>
                            <tr key={player.id} onClick={e => props.handleAdd(player)}>
                                <td>
                                    {
                                        (player.teamId !== undefined && player.teamId !== 0) &&
                                            <Team team={{id: player.teamId, name: ''}} small={true} onlyLogo={true}/>
                                    }
                                    {player.position}
                                </td>
                                <td>{player.stats.be}</td>
                                <td>{player.stats.st}</td>
                                <td>{player.stats.ge}</td>
                                <td>{player.stats.wg}</td>
                                <td>{player.stats.rw}</td>
                                <td>{player.stats.skill}</td>
                                <td>{player.kosten}</td>
                            </tr>
                        )
                    }
                    </tbody>
                </Table>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
}